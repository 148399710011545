import { useTranslation } from 'react-i18next';

import styled from '@emotion/styled';

import type { User } from '@innovamat/ga-features';
import { Select } from '@innovamat/glimmer-components';
import { useCoursesQuery } from '@innovamat/glow-api-client';

const SelectContainer = styled.div`
  width: 15.625rem;
`;

type CourseDropdownProps = {
  onSelectCourseOrder: (course: string) => void;
  selectedCourseOrder: string | undefined;
  user: User | undefined;
};

const CoursesDropdown: React.FC<CourseDropdownProps> = ({
  onSelectCourseOrder,
  selectedCourseOrder,
  user,
}) => {
  const { data: coursesData } = useCoursesQuery(
    {
      regionCode: user?.region ?? '',
    },
    {
      enabled: !!user?.region && !!user?.organizationId,
    }
  );
  const { t } = useTranslation();

  const ascendingOrderedCourses = coursesData?.courses?.sort((a, b) => {
    const orderA = a?.order ?? Number.MAX_SAFE_INTEGER;
    const orderB = b?.order ?? Number.MAX_SAFE_INTEGER;
    return orderA - orderB;
  });

  const allCoursesOption = {
    label: t('familyPayments.courseFilterPlaceholder'),
    value: '',
  };

  const coursesOptions =
    ascendingOrderedCourses?.map((course) => ({
      label: course?.name ?? '',
      value: course?.order?.toString() ?? '',
    })) ?? [];

  return (
    <SelectContainer>
      <Select
        placeholder={t('familyPayments.courseFilterPlaceholder')}
        menuPortalTarget={document.body}
        onChange={(option) => {
          onSelectCourseOrder(option?.value ?? '');
        }}
        options={[allCoursesOption, ...coursesOptions]}
        value={selectedCourseOrder}
      />
    </SelectContainer>
  );
};

export { CoursesDropdown };
