import { ReactNode, useLayoutEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import {
  ColumnDef,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from '@tanstack/react-table';

import styled from '@emotion/styled';

import type { OrderStatus } from '@innovamat/innova-components';
import {
  ExpandButton,
  OrderReturnTag,
  OrderStatusTag,
  TableBody,
  TableCell,
  TableComponent,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TableWrapper,
} from '@innovamat/innova-components'; // Todo migrate this to glimmer-components when design is ready

import {
  useCoursesQuery,
  useMaterialOrdersQuery,
} from '@innovamat/glow-api-client';
import { useUser } from '@innovamat/ga-features';
import { locales } from '@innovamat/localization';
import {
  Loader,
  HeadingSection,
  EmptyState,
  Grid,
} from '@innovamat/glimmer-components';

import { useCartExitPrompt } from '../../hooks/use-cart-exit-prompt';
import { useCurrentMaterialsContext } from '../../providers/use-current-materials-provider';

import { HistoryData, HistoryRow } from './orders-returns-history.types';
import { SubRow } from './components/sub-row';
import { EmptyClassroom } from '@innovamat/glimmer-assets';

const MessageBox = styled.div`
  align-items: center;
  background-color: ${({ theme }) =>
    theme.tokens.color.alias.cm.bg['bg-info'].value};
  border-radius: 1rem;
  display: inline-flex;
  justify-content: center;
  padding: 0.5rem 1rem;
`;

const LoaderText = styled.span`
  margin-left: 0.5rem;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const TableContainer = styled(TableWrapper)`
  margin-top: 3rem;
`;

function OrdersReturnsHistory(): JSX.Element {
  const [tableData, setTableData] = useState<HistoryData[]>([]);
  const [expanded, setExpanded] = useState<ExpandedState>({});

  const { cart } = useCurrentMaterialsContext();

  const { user } = useUser();

  const { t } = useTranslation();

  const getOrderStatus = (rowOriginal: HistoryData): ReactNode => {
    if (
      rowOriginal.orderStatus === 'cancel' ||
      rowOriginal.orderStatus === 'completed' ||
      rowOriginal.orderStatus === 'returned'
    ) {
      return rowOriginal.orderStatus;
    }

    return rowOriginal.orderType;
  };

  const renderMaxReturnDate = (
    returnDeadlineDate: HistoryData['returnDeadlineDate'] | null
  ): string | JSX.Element => {
    if (!returnDeadlineDate) {
      return '—';
    }

    const locale = locales.getISO6391(user?.locale ?? '');

    const maxReturnDate = new Date(returnDeadlineDate);
    const today = new Date();

    if (maxReturnDate > today) {
      return t(
        'ordersReturnsHistory.returnDeadlineDate.until',
        'hasta el {{date}}',
        {
          date: convertToDateFormat(returnDeadlineDate.toString(), locale),
        }
      );
    }

    return (
      <MessageBox>
        <span>{t('ordersReturnsHistory.returnDeadlineDate.expired')}</span>
      </MessageBox>
    );
  };

  const columns = useMemo<ColumnDef<HistoryData, any>[]>(
    () => [
      {
        header: t('ordersReturnsHistory.createdAt'),
        accessorKey: 'createdAt',
        size: 15,
        minSize: 15,
        maxSize: 15,
      },
      {
        header: t('ordersReturnsHistory.orderType'),
        accessorKey: 'orderType',
        cell: (props) => (
          <OrderReturnTag
            isOrder={props.getValue() === 'expand'}
            tag={t(`ordersReturnsHistory.${props.getValue()}`)}
          />
        ),
        size: 15,
        minSize: 15,
        maxSize: 15,
      },
      {
        header: t('ordersReturnsHistory.estimateNumber'),
        accessorKey: 'estimateNumber',
        size: 15,
        minSize: 15,
        maxSize: 15,
      },
      {
        header: t('ordersReturnsHistory.readableId'),
        accessorKey: 'readableId',
        size: 15,
        minSize: 15,
        maxSize: 15,
      },
      {
        header: t('ordersReturnsHistory.orderStatus'),
        accessorKey: 'orderStatus',
        cell: (props) => {
          const status = getOrderStatus(props.row.original) as OrderStatus;

          return (
            <OrderStatusTag
              orderStatus={status}
              tag={t(`ordersReturnsHistory.orderStatus.${status}`)}
            />
          );
        },
        size: 15,
        minSize: 15,
        maxSize: 15,
      },
      {
        header: t('ordersReturnsHistory.maxReturnDate'),
        accessorKey: 'returnDeadlineDate',
        cell: (props) => renderMaxReturnDate(props.getValue()),
        size: 15,
        minSize: 15,
        maxSize: 15,
      },
      {
        header: () => null,
        id: 'expander',
        size: 6,
        minSize: 6,
        maxSize: 6,
        cell: ({ row }) => (
          <ExpandButton
            row={row}
            onRowClick={() => handleRowClick(row)}
            isExpanded={row.getIsExpanded()}
          />
        ),
      },
    ],
    []
  );

  const { data: coursesData } = useCoursesQuery(
    {
      regionCode: user?.region ?? '',
    },
    {
      enabled: !!user?.region,
    }
  );

  const organization = user?.organization;

  const { data: orders, isLoading: isLoadingOrders } = useMaterialOrdersQuery(
    {
      organizationId: organization?.id ?? '',
      academicYearId: organization?.academicYearId ?? '',
      page: 1,
      pageSize: 100,
    },
    {
      enabled: !!organization,
    }
  );

  const { getHeaderGroups, getRowModel } = useReactTable({
    data: tableData,
    columns,
    state: { expanded },
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  useCartExitPrompt(cart.length === 0);

  // TODO change any
  const mapOrders = (order: any): HistoryData => {
    const locale = locales.getISO6391(user?.locale ?? '');

    return {
      ...order,
      subRows: null,
      createdAt: convertToDateFormat(order.createdAt, locale),
    } as HistoryData;
  };

  const convertToDateFormat = (date: string, locale: string): string => {
    return new Intl.DateTimeFormat(locale, {
      dateStyle: 'long',
    }).format(new Date(date));
  };

  const handleRowClick = async (row: HistoryRow): Promise<void> => {
    row.toggleExpanded();
  };

  const renderTableBody = (): JSX.Element | JSX.Element[] => {
    if (isLoadingOrders) {
      return (
        <TableRow>
          <TableCell colSpan={6}>
            <LoaderContainer>
              <Loader />
              <LoaderText>{t('common.loadingContent')}</LoaderText>
            </LoaderContainer>
          </TableCell>
        </TableRow>
      );
    }

    return getRowModel().rows.map((row) => (
      <>
        <TableRow key={row.original.id} isExpanded={row.getIsExpanded()}>
          {row.getVisibleCells().map((cell) => (
            <TableCell key={cell.id}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </TableCell>
          ))}
        </TableRow>

        {row.getIsExpanded() && (
          <SubRow
            coursesData={coursesData}
            row={row}
            setTableData={setTableData}
            tableData={tableData}
          />
        )}
      </>
    ));
  };

  useLayoutEffect(() => {
    if (orders?.materialOrders) {
      setTableData(orders.materialOrders.map(mapOrders));
    }
  }, [orders]);

  return (
    <>
      <HeadingSection title={t('ordersReturnsHistory.title')} />

      {tableData.length === 0 && !isLoadingOrders ? (
        <EmptyState
          Image={EmptyClassroom}
          subtitle={t('ordersReturnsHistory.noOrdersSubtitle')}
          title={t('ordersReturnsHistory.noOrders')}
        />
      ) : (
        <TableContainer>
          <TableComponent>
            <TableHeader>
              {getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <TableHeaderCell
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{ width: `${header.getSize()}%` }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHeaderCell>
                  ))}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>{renderTableBody()}</TableBody>
          </TableComponent>
        </TableContainer>
      )}
    </>
  );
}

export { OrdersReturnsHistory };
