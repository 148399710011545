import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-domv6';

import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import styled from '@emotion/styled';

import {
  OrderType,
  OrderTypeSale,
  useCreateOrderMutation,
  useGetFinalCustomersOfBillingQuery,
  useGetShippingAddressByOrganizationIdQuery,
} from '@innovamat/glow-api-client';

import type {
  Address,
  CreateOrderBody,
  OrderItem,
  Organization,
} from '@innovamat/glow-api-client';

import { APP_PATHS, useUser } from '@innovamat/ga-features';

import {
  Button,
  CheckCardGroup,
  DetailsCard,
  Grid,
  Grounder,
  HeadingSection,
  snack,
} from '@innovamat/glimmer-components';

import { useCurrentMaterialsContext } from '../../providers/use-current-materials-provider';

import type { Material } from '../current-materials/current-materials.types';

import { ShippingAddressSkeleton } from '../../skeletons/shipping-address-skeleton';

import { useCartExitPrompt } from '../../hooks/use-cart-exit-prompt';

const Wrapper = styled.div`
  margin-top: 0.5rem;
`;

const ContentContainer = styled.div`
  gap: 1.5rem;
  display: flex;
  flex-direction: column;
`;

const StyledRow = styled(Grid.Row)`
  padding-top: 1.5rem;
`;

const StyledColumn = styled(Grid.Col)`
  padding: 0 !important;
`;

const ConfirmButton = styled(Button)`
  width: 100%;
`;

type AddressData = {
  id: string;
  rows: string[];
};

function MaterialsCheckout(): JSX.Element {
  const { cart, clearCart, setIsEditMode } = useCurrentMaterialsContext();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useUser();

  const organizationId = user?.organizationId ?? '';
  const organization = user?.organization;

  const ORGANIZATION_MATERIALS_URL = `${APP_PATHS.ORGANIZATION_URL}/${organizationId}${APP_PATHS.ORDERS_RETURNS_URL}${APP_PATHS.CURRENT_MATERIALS_URL}`;

  const { data: shippingAddresses, isLoading: isLoadingShippingAddresses } =
    useGetShippingAddressByOrganizationIdQuery({
      organizationId,
      visibleWeb: true,
    });

  const { data: billingData, isLoading: isLoadingBillingData } =
    useGetFinalCustomersOfBillingQuery({ organizationId });

  const { mutate: createOrder } = useCreateOrderMutation();

  const [selectedShippingAddressId, setSelectedShippingAddressId] = useState<
    string | undefined
  >();

  const [selectedBillingId, setSelectedBillingId] = useState<
    string | undefined
  >();

  const [shippingAddressRows, setShippingAddressRows] = useState<
    AddressData[] | undefined
  >();

  const [billingAddressRows, setBillingAddressRows] = useState<
    AddressData[] | undefined
  >();

  const [isProcessingOrders, setIsProcessingOrders] = useState<boolean>(false);

  useCartExitPrompt(cart.length === 0);

  const isConfirmButtonDisabled = (): boolean =>
    isLoadingShippingAddresses ||
    isLoadingBillingData ||
    isProcessingOrders ||
    !billingAddressRows?.length ||
    !shippingAddressRows?.length;

  const getShippingAddresses = (): AddressData[] | undefined => {
    return shippingAddresses?.getFinalCustomerErpByOrganizationId?.shippingAddress?.map(
      (shippingAddress, index) => {
        if (index === 0) {
          setSelectedShippingAddressId(shippingAddress?.id);
        }

        return {
          id: shippingAddress?.id ?? '',
          rows: [
            shippingAddress?.street ?? '',
            `${shippingAddress?.zip ?? ''} ${
              shippingAddress?.province ?? ''
            }`.trim(),
            shippingAddress?.city ?? '',
          ],
        };
      }
    );
  };

  const getBillingAddresses = (): AddressData[] | undefined => {
    return billingData?.getFinalCustomersOfBilling?.map(
      (billingCustomer, index) => {
        if (index === 0) {
          setSelectedBillingId(billingCustomer?.id);
        }

        return {
          id: billingCustomer?.id ?? '',
          rows: [
            billingCustomer?.name ?? '',
            billingCustomer?.billingAddress.street ?? '',
            `${billingCustomer?.billingAddress.zip ?? ''} ${
              billingCustomer?.billingAddress.province ?? ''
            }`.trim(),
            billingCustomer?.billingAddress.city ?? '',
            billingCustomer?.cif ?? '',
          ],
        };
      }
    );
  };

  const goToMaterialsPage = () => {
    navigate(ORGANIZATION_MATERIALS_URL);
  };

  const getAddressById = (
    elements: Address[] | undefined | null,
    id: string | undefined
  ) => elements?.find((currentShipping) => currentShipping?.id === id);

  const getFilteredMaterials = (
    materialsFilter: (material: Material) => boolean
  ) =>
    cart.filter(materialsFilter).map((currentMaterial: any) => ({
      id: currentMaterial.id,
      quantity: Math.abs(currentMaterial.totalOperationQuantity),
    }));

  const createOrderRequest = (
    materials: OrderItem[],
    orderData: Omit<CreateOrderBody, 'orderId' | 'orderType'>,
    orderType: OrderType
  ) =>
    new Promise((resolve) => {
      if (materials.length > 0) {
        createOrder(
          {
            order: {
              ...orderData,
              items: materials,
              orderId: uuidv4(),
              orderType,
            },
          },
          {
            onSuccess: (_, createOrderResponse) => {
              resolve(createOrderResponse);
            },
          }
        );
      } else {
        resolve(undefined);
      }
    });

  const handleCancel = (): void => {
    goToMaterialsPage();
  };

  const handleCreateOrder = (): void => {
    setIsProcessingOrders(true);

    const shippingAddress = getAddressById(
      shippingAddresses?.getFinalCustomerErpByOrganizationId
        ?.shippingAddress as Address[],
      selectedShippingAddressId
    );

    const billingCustomer = billingData?.getFinalCustomersOfBilling?.find(
      (currentBillingCustomer) =>
        currentBillingCustomer?.id === selectedBillingId
    );

    const materialsToExpand = getFilteredMaterials(
      (material) => material.totalOperationQuantity > 0
    );
    const materialsToReturn = getFilteredMaterials(
      (material) => material.totalOperationQuantity < 0
    );

    const order = {
      organizationId,
      billingCustomerId: billingCustomer?.id ?? '',
      billingCustomerName: billingCustomer?.name ?? '',
      academicYearId: (organization as Organization).academicYearId!,
      orderTypeSale: OrderTypeSale.Direct,
      userId: user?.id ?? '', // Todo
      shippingAddress: {
        administrativeRegion: shippingAddress?.administrativeRegion ?? '',
        formattedAddress: shippingAddress?.formattedAddress ?? '',
        city: shippingAddress?.city ?? '',
        country: shippingAddress?.country ?? '',
        province: shippingAddress?.province ?? '',
        reference: shippingAddress?.reference ?? '',
        street: shippingAddress?.street ?? '',
        zip: shippingAddress?.zip ?? '',
      },
    };

    const expandOrderRequest = createOrderRequest(
      materialsToExpand,
      order as CreateOrderBody,
      OrderType.Expand
    );
    const returnOrderRequest = createOrderRequest(
      materialsToReturn,
      order as CreateOrderBody,
      OrderType.Return
    );

    Promise.all([expandOrderRequest, returnOrderRequest])
      .then((responses) => {
        const errors = responses.filter((response: any) => response?.errors); // Todo check this any

        if (!errors.length || (responses.length > 1 && errors.length === 1)) {
          setIsEditMode(false);
          clearCart();

          if (!errors.length) {
            snack.success(t('materials.checkout.orderPlacedSuccessfully'));
          }

          goToMaterialsPage();
        }
      })
      .then(() => setIsProcessingOrders(false));
  };

  const renderBillingAddressBody = () => {
    if (isLoadingBillingData) {
      return (
        <Wrapper>
          <ShippingAddressSkeleton />
        </Wrapper>
      );
    }

    if (!billingAddressRows?.length) {
      return (
        <Wrapper>
          <p>{t('materialsCheckout.noBillingAddress')}</p>
        </Wrapper>
      );
    }

    return (
      <CheckCardGroup
        data={billingAddressRows}
        onChange={setSelectedBillingId}
        selectedId={selectedBillingId}
      />
    );
  };

  const renderShippingAddressBody = () => {
    if (isLoadingShippingAddresses) {
      return (
        <Wrapper>
          <ShippingAddressSkeleton />
        </Wrapper>
      );
    }

    if (!shippingAddressRows?.length) {
      return (
        <Wrapper>
          <p>{t('materialsCheckout.noShippingAddress')}</p>
        </Wrapper>
      );
    }

    return (
      <CheckCardGroup
        data={shippingAddressRows}
        onChange={setSelectedShippingAddressId}
        selectedId={selectedShippingAddressId}
      />
    );
  };

  useEffect(() => {
    if (!selectedShippingAddressId) {
      setShippingAddressRows(getShippingAddresses());
    }
  }, [isLoadingShippingAddresses]);

  useEffect(() => {
    if (!selectedBillingId) {
      setBillingAddressRows(getBillingAddresses());
    }
  }, [isLoadingBillingData]);

  useEffect(() => {
    if (cart.length === 0 && user?.organizationId) {
      goToMaterialsPage();
    }
  }, [cart, user?.organizationId]);

  return (
    <>
      <HeadingSection
        title={t('materialsCheckout.title')}
        actions={
          <Button variant="secondary" onClick={handleCancel}>
            {t('common.return')}
          </Button>
        }
      />

      <Grid.Container shouldFill={false}>
        <StyledRow>
          <StyledColumn md={8}>
            <ContentContainer>
              <DetailsCard
                header={{
                  count: '1.',
                  title: t('materials.checkout.selectShippingAddress'),
                }}
                body={renderShippingAddressBody()}
              />
              <DetailsCard
                header={{
                  count: '2.',
                  title: t('materials.checkout.billingInformation'),
                }}
                body={renderBillingAddressBody()}
              />
              <ConfirmButton
                disabled={isConfirmButtonDisabled()}
                loading={isProcessingOrders}
                onClick={handleCreateOrder}
              >
                {t('materials.checkout.confirm')}
              </ConfirmButton>
            </ContentContainer>
          </StyledColumn>
        </StyledRow>
      </Grid.Container>
      <Grounder />
    </>
  );
}

export { MaterialsCheckout };
