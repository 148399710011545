import { useRef, useState } from 'react';
import { DayPicker, DayPickerSingleProps } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import styled from '@emotion/styled';
import { useOnClickOutside } from '@innovamat/hooks';
import { getElevation } from '../elevation';
import { dates } from '@innovamat/radiance-utils';
import languageMapper from './date-picker-language-mapper';
import { Input } from '../input';

export interface DatePickerInputProps
  extends Omit<DayPickerSingleProps, 'mode'> {
  initialDate?: Date;
  handleDateChange: (date: Date) => void;
  closeOnClick?: boolean;
  enabledDays?: number[];
  dataTestId?: string;
  dataTestIdCalendar?: string;
  fromDate: Date;
  toDate: Date;
  readOnly?: boolean;
}

export function DatePickerInput({
  initialDate,
  handleDateChange,
  closeOnClick = true,
  enabledDays,
  dataTestId,
  dataTestIdCalendar,
  fromDate,
  toDate,
  readOnly = false,
  ...props
}: DatePickerInputProps): JSX.Element {
  const [isDayPickerVisible, setIsDayPickerVisible] = useState(false);
  const [date, setDate] = useState<Date | undefined>(initialDate);

  const ref = useRef(null);

  useOnClickOutside(ref, () => {
    setIsDayPickerVisible(false);
  });

  const handleDayPickerVisibility = (): void => {
    if (!readOnly) {
      setIsDayPickerVisible(
        (prevIsDayPickerVisible) => !prevIsDayPickerVisible
      );
    }
  };

  const onSelectDate = (selectedDate: Date): void => {
    if (selectedDate >= fromDate && selectedDate <= toDate) {
      setDate(selectedDate);
      handleDateChange(selectedDate);
      if (closeOnClick) setIsDayPickerVisible(false);
    }
  };

  const inputValue = dates.getFormattedDate(date);
  const locale = languageMapper();
  return (
    <Container ref={ref}>
      <InputContainer onClick={handleDayPickerVisibility} readOnly={readOnly}>
        <Input
          value={inputValue}
          onChange={() => null}
          placeholder={dates.getDateFormat()}
          dataTestId={dataTestId}
        />
      </InputContainer>
      <DayPickerContainer isVisible={isDayPickerVisible}>
        <DayPickerStyled
          mode="single"
          selected={date}
          onDayClick={onSelectDate}
          fixedWeeks
          weekStartsOn={dates.getWeekStartDay()}
          locale={locale}
          data-testid={dataTestIdCalendar}
          {...props}
        />
      </DayPickerContainer>
    </Container>
  );
}

export default DatePickerInput;

const DayPickerStyled = styled(DayPicker)`
  --rdp-cell-size: 40px; /* Size of the day cells. */
  --rdp-caption-font-size: 18px; /* Font size for the caption labels. */
  --rdp-accent-color: ${({ theme }) =>
    theme.tokens.color.alias.cm.icon['icon-accent']
      .value}; /* Accent color for the background of selected days. */
  --rdp-background-color: ${({ theme }) =>
    theme.tokens.color.global.teal.teal200
      .value}; /* Background color for the hovered/focused elements. */
  --rdp-accent-color-dark: ${({ theme }) =>
    theme.tokens.color.alias.cm.icon['icon-accent']
      .value}; /* Accent color for the background of selected days (to use in dark-mode). */
  --rdp-background-color-dark: ${({ theme }) =>
    theme.tokens.color.global.teal.teal200
      .value}; /* Background color for the hovered/focused elements (to use in dark-mode). */
  --rdp-outline: 2px solid var(--rdp-accent-color); /* Outline border for focused elements */
  --rdp-outline-selected: 3px solid var(--rdp-accent-color); /* Outline border for focused _and_ selected elements */
  --rdp-selected-color: #fff; /* Color of selected day text */
`;

const Container = styled.div`
  position: relative;
`;

const InputContainer = styled.div<{ readOnly: boolean }>`
  cursor: ${({ readOnly }) => (readOnly ? '' : 'pointer')};
  > * {
    cursor: ${({ readOnly }) => (readOnly ? '' : 'pointer')};
    pointer-events: none;
  }
`;

const DayPickerContainer = styled.div<{
  isVisible: boolean;
}>`
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  position: absolute;
  background-color: ${({ theme }) =>
    theme.tokens.color.alias.cm.surface['surface-primary'].value};
  ${({ theme }) => getElevation(theme, 'elevation 2')};
  z-index: 100;
  top: 100%;
`;
